import React, { useState, useEffect } from 'react';
import { collection, onSnapshot, doc, addDoc, deleteDoc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, Container, Typography, Grid, Card, CardContent, IconButton, Dialog, DialogTitle, DialogContent, Box, TextField, Button, FormControl, Switch, FormControlLabel, Select, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { useAlert } from './AlertContext'; // Remplacez par la bibliothèque d'alertes que vous utilisez
import { auth, db } from './firebase';
import UrneCard from './views/urneCard';
import CloseIcon from '@mui/icons-material/Close';
import { DataGrid } from '@mui/x-data-grid';
import { localeText } from './config/localeText';


export const GetUrnes = (userRole, onUpdateUrnes) => {
    userRole.userRole && (userRole = userRole.userRole);
    const [urnes, setUrnes] = useState([]);
    const [viewUrne, setViewUrne] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [openAddModal, setOpenAddModal] = useState(false);
    const [openPhotoModal, setOpenPhotoModal] = useState(false);
    const [isTableView, setIsTableView] = useState(false);
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:600px)');
    const [loading, setLoading] = useState(true);
    const { showAlert } = useAlert();
    const [maxOrder, setMaxOrder] = useState(0);

    const fetchUrnes = () => {
        setLoading(true);
        const urnesCollectionRef = collection(db, "urnes");

        const unsubscribe = onSnapshot(urnesCollectionRef, (snapshot) => {
            const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            data.sort((a, b) => {
                const orderA = a.order || 0;
                const orderB = b.order || 0;
                return orderA - orderB;
            });
            const maxOrderValue = data.reduce((max, urne) => Math.max(max, urne.order || 0), 0);
            setMaxOrder(maxOrderValue);
            setUrnes(data);
            setLoading(false);
        }, (error) => {
            console.error("Erreur lors de la récupération des urnes:", error);
            setLoading(false);
        });

        return unsubscribe;
    };

    useEffect(() => {
        const unsubscribe = fetchUrnes();
        return () => unsubscribe && unsubscribe();
    }, []);

    const handleRowClick = (urne) => {
        if (userRole === "admin") {
            setViewUrne(urne);
            setOpenAddModal(true);
        } else {
            setViewUrne(urne);
            setOpenPhotoModal(true);
        } 
    };

    const handleViewUrne = (urne) => {
        setViewUrne(urne);
        setOpenPhotoModal(true);
    };

    const handleCloseAddModal = () => {
        setOpenAddModal(false);
        setViewUrne(null);
    };

    const handleDeleteUrne = async (urne) => {
        try {
            await deleteDoc(doc(db, "urnes", urne.id)); // Suppression réelle de la base de données
            showAlert("Urne supprimée avec succès !", 'success');
        } catch (error) {
            console.error("Erreur lors de la suppression de l'urne :", error);
            showAlert("Erreur lors de la suppression.", 'error');
        }
    };

    const handleOrderChange = (urne, newOrder) => {
        console.log("newOrder", newOrder);
        const urneRef = doc(db, "urnes", urne.id);
        const targetUrne = urnes.find(u => u.order === newOrder - 1);
        console.log("targetUrne", targetUrne);
    
        if (targetUrne) {
            const oldOrder = urne.order;
            const targetUrneRef = doc(db, "urnes", targetUrne.id);
    
            // Mettre à jour l'ordre de l'urne actuelle
            updateDoc(urneRef, { order: newOrder - 1 })
                .then(() => {
                    console.log("Ordre de l'urne mis à jour avec succès !");
                })
                .catch((error) => {
                    console.error("Erreur lors de la mise à jour de l'ordre de l'urne :", error);
                });
    
            // Mettre à jour l'ordre de l'urne cible
            updateDoc(targetUrneRef, { order: oldOrder })
                .then(() => {
                    console.log("Ordre de l'urne cible mis à jour avec succès !");
                })
                .catch((error) => {
                    console.error("Erreur lors de la mise à jour de l'ordre de l'urne cible :", error);
                });
        }
    };

    const columns = [
        { 
            field: 'order', 
            headerName: 'Position', 
            width: 100,
            renderCell: (params) => (
                userRole === 'admin' ? (
                    <Select
                        value={params.value}
                        onChange={(e) => handleOrderChange(params.row, e.target.value + 1)}
                        size="small"
                        sx={{ 
                            minWidth: 70,
                            '.MuiSelect-select': { 
                                py: 1,
                                backgroundColor: 'transparent'
                            }
                        }}
                    >
                        {urnes.map((_, index) => (
                            <MenuItem key={index} value={index}>
                                {index + 1}
                            </MenuItem>
                        ))}
                    </Select>
                ) : (
                    <Typography variant="body1" fontWeight="bold">
                        {params.value + 1}
                    </Typography>
                )
            )
        },
        { field: 'titre', headerName: 'Titre', flex: 1 }
    ];

    const handleProcessRowUpdate = async (newRow, oldRow) => {
        if (newRow.order !== oldRow.order) {
            await handleOrderChange(oldRow, newRow.order + 1);
        }
        return newRow;
    };

    return (
        <Container maxWidth="lg">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>Liste des urnes</Typography>
                {!isMobile && userRole === 'admin' && (
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isTableView}
                                onChange={(e) => setIsTableView(e.target.checked)}
                                name="tableView"
                            />
                        }
                        label="Réorganiser"
                    />
                )}
            </Box>
            {isMobile ? (
                <Grid container spacing={3} justifyContent="center" sx={{ paddingBottom: 10, overflow: 'auto' }}>
                    {urnes.map((urne) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={urne.id}>
                            <Card variant="outlined" sx={{ height: '100%', padding: 0, paddingBottom:0, border: 'none' }} onClick={() => handleRowClick(urne)} style={{ cursor: 'pointer' }}>
                                <CardContent style={{ padding: 0 }}>
                                    <UrneCard urne={urne} onClick={() => handleRowClick(urne)} onDelete={() => handleDeleteUrne(urne)} userRole={userRole} maxOrder={maxOrder} handleOrderChange={handleOrderChange} onView={() => handleViewUrne(urne)} />
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <>
                    {isTableView ? (
                        <Box sx={{ width: '100%' }}>
                            <DataGrid
                                rows={urnes}
                                columns={columns}
                                hideFooter={true}
                                autoHeight
                                disableSelectionOnClick
                                processRowUpdate={handleProcessRowUpdate}
                                experimentalFeatures={{ newEditingApi: true }}
                                localeText={localeText}
                                sx={{
                                    '& .MuiDataGrid-columnHeaders': {
                                        backgroundColor: '#7cb1e2',
                                        color: 'white',
                                    },
                                    '& .MuiDataGrid-row:nth-of-type(odd)': {
                                        backgroundColor: '#ffffff',
                                    },
                                    '& .MuiDataGrid-row:nth-of-type(even)': {
                                        backgroundColor: '#7cb1e2',
                                        color: 'white',
                                    },
                                }}
                            />
                        </Box>
                    ) : (
                        <>
                            <Grid container spacing={3} justifyContent="center" sx={{ paddingBottom: 10, overflow: 'auto' }}>
                                {urnes.map((urne) => (
                                    <Grid item xs={12} sm={12} md={6} lg={6} key={urne.id}>
                                        <Card variant="outlined" sx={{ height: '100%', padding: 0, paddingBottom: 1.5 }} onClick={() => handleRowClick(urne)} style={{ cursor: 'pointer' }}>
                                            <CardContent style={{ padding: 0 }}>
                                                <UrneCard urne={urne} onClick={() => handleRowClick(urne)} onDelete={() => handleDeleteUrne(urne)} userRole={userRole} maxOrder={maxOrder} handleOrderChange={handleOrderChange} onView={() => handleViewUrne(urne)} />
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )}
                    {userRole === 'admin' && (
                        <Fab
                            color="primary"
                            aria-label="add"
                            onClick={() => setOpenAddModal(true)}
                            sx={{
                                position: 'fixed',
                                bottom: 80,
                                right: 30,
                                backgroundColor: "#1976D2"
                            }}
                        >
                            <AddIcon />
                        </Fab>
                    )}
                </>
            )}
            <AddUrnes urneProp={viewUrne} userRole={userRole} onClose={handleCloseAddModal} open={openAddModal} onUpdateUrnes={onUpdateUrnes} urnes={urnes} />

            <Dialog open={openPhotoModal} onClose={() => setOpenPhotoModal(false)} maxWidth="md" fullWidth>
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpenPhotoModal(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <img src={viewUrne?.image} alt={viewUrne?.titre} style={{ width: '100%' }} />
                </DialogContent>
            </Dialog>
        </Container>
    );
};

export const AddUrnes = ({ urneProp, userRole, onClose, open, onUpdateUrnes, urnes }) => {
    const { showAlert } = useAlert();
    const [urne, setUrne] = useState({
        titre: '',
        texte: '',
        image: '',
        prix: '',
        capacity: '',
        nb: 0,
        id: null,
    });

    useEffect(() => {
        if (urneProp) {
            setUrne(urneProp);
        } else {
            const maxOrder = urnes.reduce((max, urne) => Math.max(max, urne.order || 0), 0);
            setUrne({ 
                titre: '', 
                texte: '', 
                image: '', 
                prix: '',
                capacity: '',
                nb: 0,
                id: null, 
                order: maxOrder + 1 
            });
        }
    }, [urneProp, urnes]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setUrne(prev => ({ ...prev, [name]: value }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            // Vérifiez si un fichier est sélectionné uniquement si le champ de fichier existe
            const fileInput = document.querySelector('input[name="image"]');
            let fileURL = urne.image; // Conserver l'URL existante par défaut
    
            if (fileInput && fileInput.files[0]) {
                // Téléchargez le fichier dans Firebase Storage
                const storage = getStorage();
                const storageRef = ref(storage, `images/${fileInput.files[0].name}`);
                await uploadBytes(storageRef, fileInput.files[0]);
    
                // Obtenez l'URL de téléchargement
                fileURL = await getDownloadURL(storageRef);
            }
    
            // Calculer la nouvelle valeur de 'order'
            const maxOrder = urnes.reduce((max, urne) => Math.max(max, urne.order || 0), 0);
            const newOrder = maxOrder + 1;
    
            // Mettez à jour la demande avec l'URL du fichier et l'ordre
            const updatedUrne = { ...urne, image: fileURL, order: urne.id ? urne.order : newOrder };
    
            if (urne.id) {
                // Mise à jour d'une demande existante
                await updateDoc(doc(db, "urnes", urne.id), updatedUrne);
                showAlert("Urne mise à jour avec succès !", 'success');
            } else {
                // Création d'une nouvelle demande
                const { id, ...data } = updatedUrne;
                const docRef = await addDoc(collection(db, "urnes"), data);
                setUrne({ ...data, id: docRef.id });
                showAlert("Nouvelle urne créée avec succès !", 'success');
            }
    
            onClose();
        } catch (error) {
            console.error("Erreur lors de la soumission de l'urne :", error);
            showAlert("Erreur lors de la soumission.", 'error');
        }
    };

    return (
        <Dialog open={open} onClose={() => { onClose(); setUrne({ titre: '', texte: '', image: '', prix: '', capacity: '', nb: 0 }); }} maxWidth="md" fullWidth>
            <DialogTitle>
                {urne.id ? "Modifier l'urne" : "Nouvelle urne"}
                <IconButton
                    aria-label="close"
                    onClick={() => { onClose(); setUrne({ titre: '', texte: '', image: '', prix: '', capacity: '', nb: 0 }); }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Container>
                    <Grid item xs={12}>
                        <FormControl fullWidth sx={{ marginLeft: 0, marginRight: 0, marginTop: 1, marginBottom: 1 }}>
                            <Box component="form" onSubmit={handleFormSubmit}>
                                <TextField
                                    name="titre"
                                    label="Titre"
                                    value={urne.titre}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                    sx={{ marginBottom: 2 }}
                                />
                                <TextField
                                    name="texte"
                                    label="Texte"
                                    value={urne.texte}
                                    onChange={handleChange}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    sx={{ marginBottom: 2 }}
                                />
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            name="prix"
                                            label="Prix (€)"
                                            type="number"
                                            value={urne.prix}
                                            onChange={handleChange}
                                            fullWidth
                                            InputProps={{
                                                inputProps: { min: 0, step: "0.01" }
                                            }}
                                            sx={{ marginBottom: 2 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            name="capacity"
                                            label="Capacité (kg)"
                                            type="number"
                                            value={urne.capacity}
                                            onChange={handleChange}
                                            fullWidth
                                            InputProps={{
                                                inputProps: { min: 0, step: "0.1" }
                                            }}
                                            sx={{ marginBottom: 2 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            name="nb"
                                            label="Stock"
                                            type="number"
                                            value={urne.nb}
                                            onChange={handleChange}
                                            fullWidth
                                            InputProps={{
                                                inputProps: { min: 0, step: 1 }
                                            }}
                                            sx={{ marginBottom: 2 }}
                                        />
                                    </Grid>
                                </Grid>
                                {urne.id && urne.image && (
                                    <Box sx={{ position: 'relative', width: 'fit-content', mb: 2 }}>
                                        <img 
                                            src={urne.image} 
                                            alt={urne.titre}
                                            style={{ 
                                                width: '150px', 
                                                height: '150px', 
                                                objectFit: 'cover',
                                                borderRadius: '4px'
                                            }}
                                        />
                                        <IconButton
                                            onClick={() => setUrne(prev => ({ ...prev, image: '' }))}
                                            sx={{
                                                position: 'absolute',
                                                top: -10,
                                                right: -10,
                                                backgroundColor: '#ff4444',
                                                color: 'white',
                                                border: '2px solid white',
                                                boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                                                '&:hover': {
                                                    backgroundColor: '#cc0000'
                                                }
                                            }}
                                            size="small"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                )}
                                {(!urne.image || urne.image === '') && (
                                    <TextField
                                        name="image"
                                        type="file"
                                        onChange={handleChange}
                                        fullWidth
                                        required={true}
                                        sx={{ marginBottom: 2 }}
                                    />
                                )}
                                <TextField
                                    name="order"
                                    label="Ordre"
                                    type="number"
                                    value={urne.order + 1 || ''}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                    sx={{ marginBottom: 2 }}
                                />
                                <Button type='submit' variant="contained" color="success" sx={{ marginTop: 2 }}>
                                    Enregistrer
                                </Button>
                            </Box>
                        </FormControl>
                    </Grid>
                </Container>
            </DialogContent>
        </Dialog>
    );
};
