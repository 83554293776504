import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Grid, Chip, Stack } from '@mui/material';
import EuroIcon from '@mui/icons-material/Euro';
import ScaleIcon from '@mui/icons-material/Scale';
import Inventory2Icon from '@mui/icons-material/Inventory2';

export default function UrneCard({ urne, onClick, onDelete, userRole, maxOrder, handleOrderChange, onView }) {
    const { texte, image, titre, prix, capacity, nb } = urne;
    // console.log("urne", urne);
    const handleCardClick = (event) => {
        if (event.target.tagName === 'LI') {
            event.stopPropagation();
            return;
        }
        onClick();
    };

    return (
        <Card
            sx={{ maxWidth: 500 }}
            onClick={handleCardClick}
            style={{ cursor: 'pointer' }}>
            <CardMedia
                component="img"
                sx={{ 
                    height: '100%', 
                    width: '100%', 
                    objectFit: 'contain',
                    display: 'block',
                    margin: '0 auto'
                }}
                image={image}
                title={titre}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {titre}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    {texte}
                </Typography>
                <Stack direction="row" spacing={2} sx={{ mb: 1 }}>
                    {prix && (
                        <Chip
                            icon={<EuroIcon />}
                            label={`${Number(prix).toFixed(2)}€`}
                            color="primary"
                            variant="outlined"
                            size="small"
                        />
                    )}
                    {capacity && (
                        <Chip
                            icon={<ScaleIcon />}
                            label={`${capacity}kg`}
                            color="info"
                            variant="outlined"
                            size="small"
                        />
                    )}
                    <Chip
                        icon={<Inventory2Icon />}
                        label={`Stock: ${nb || 0}`}
                        color={nb > 0 ? "success" : "error"}
                        variant="outlined"
                        size="small"
                    />
                </Stack>
            </CardContent>
            {userRole === "admin" && (
                <CardActions>
                    <Select
                    value={urne.order + 1}
                    onChange={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        handleOrderChange(urne, event.target.value);
                    }}
                    size="small"
                >
                    {[...Array(maxOrder).keys()].map((num) => (
                        <MenuItem key={num + 1} value={num + 1}>
                            {num + 1}
                        </MenuItem>
                        ))}
                        <MenuItem value={maxOrder + 1}>{maxOrder + 1}</MenuItem>
                    </Select>
                    <IconButton size="small" onClick={(event) => { event.stopPropagation(); onView(); }}>
                        <VisibilityIcon />
                    </IconButton>
                    <IconButton size="small" onClick={(event) => { event.stopPropagation(); onDelete(); }}>
                        <DeleteIcon />
                    </IconButton>
                </CardActions>
            )}
        </Card>
    );
}